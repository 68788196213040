import React, { useEffect, useRef, useState } from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import process from 'process';
import * as propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, spacing, typography } from '@teliads/components/foundations';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@purpurds/purpur';
import getPaymentMethods from '../../api/card-payment/getPaymentMethods';
import useAdyenLocale from '../../hooks/useAdyenLocale';
import authorizeAndCapture from '../../api/card-payment/authorizeAndCapture';
import poll from '../../api/card-payment/poll';
import finalize from '../../api/card-payment/finalize';
import ROUTES from '../../constants/routes';
import Loading from '../Loading';
import { decimalFormatAmount, formatCurrency } from '../../helpers/amount';
import CardPaymentError from './CardPaymentError';
import { cardAuthorizeAndCapture } from '../../actions/applicationActions';
import { clickedCompleteCardPaymentButtonEvent } from '../../actions/eventActions';

export const getPaymentPayload = ({
  returnUrl = window.location.href,
  origin = `${window.location.origin}/`,
  data
}) => {
  const productOfferings = [
    {
      paymentMethodReference: 'Adyen',
      paymentType: 'direct'
    }
  ];
  return {
    ...data,
    productOfferings,
    returnUrl,
    origin
  };
};

const DecimalFormatAmountWithCurrencyWithSpan = () => {
  const { paymentMethods } = useSelector((state) => state.app);

  const currency = paymentMethods.cardPayment.currency;
  const amount = decimalFormatAmount(paymentMethods.cardPayment.amount);

  return (
    <>
      {currency === 'EUR' && <span>{formatCurrency(currency)}</span>}
      {amount}
      {currency !== 'EUR' && <span>{formatCurrency(currency)}</span>}
    </>
  );
};

const AdyenDropIn = ({ history }) => {
  const dropinRef = useRef();
  const submitRef = useRef();
  const { purchaseRequestToken, correlationId } = useSelector(
    (state) => state.app
  );
  const locale = useAdyenLocale();
  const [transactionResult, setTransactionResult] = useState(null);
  const [showDropin, setShowDropin] = useState(true);
  const [showPurchaseButton, setShowPurchaseButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isProccesingPayment, setIsProccesingPayment] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [is3dsView, setIs3dsView] = useState(false);
  const [processPurchaseRequestSuccess, setProcessPurchaseRequestSuccess] =
    useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (processPurchaseRequestSuccess) {
      history.push(ROUTES.THANK_YOU_CARD_PAYMENT);
    }
  }, [processPurchaseRequestSuccess]);

  useEffect(() => {
    if (transactionResult && transactionResult.status === 'succeeded') {
      setProcessPurchaseRequestSuccess(true);
    }
  }, [transactionResult]);

  const defaultError = {
    status: 'failed',
    reason: 'default'
  };

  const get3dsData = async () => {
    try {
      const response = await poll(purchaseRequestToken, correlationId);
      if (response.status === 202) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return await get3dsData();
      }
      if (response.status === 200) {
        setIsLoading(false);
        return response?.response?.json();
      }
      if (response.status === 400) {
        setIsLoading(false);
        setErrorOccurred(true);
        return response.response.json();
      }
      if (response.status === 410) {
        setIsLoading(false);
        setErrorOccurred(true);
        return null;
      }
    } catch (error) {
      setIsLoading(false);
      setErrorOccurred(true);
      return new Error(error);
    }
    setIsLoading(false);
    setErrorOccurred(true);
    return new Error('unknown error');
  };

  const submitPayment = async (data) => {
    setShowPurchaseButton(false);
    setIsLoading(true);
    setIs3dsView(true);

    const payload = getPaymentPayload({
      data
    });

    const authorizeAndCaptureResponse = await authorizeAndCapture(
      purchaseRequestToken,
      payload.paymentMethod,
      window.location.origin + ROUTES.CARD_SECURE_COMPLETE,
      payload.origin,
      payload.browserInfo,
      correlationId
    );

    if (
      !authorizeAndCaptureResponse ||
      authorizeAndCaptureResponse.status !== 202
    ) {
      setIsLoading(false);
      setErrorOccurred(true);
      return new Error('unknown error');
    }

    try {
      return get3dsData();
    } catch (e) {
      setShowPurchaseButton(true);
      setIsLoading(false);
      setErrorOccurred(true);
      return null;
    }
  };

  const finalizePurchase = async (data) => {
    const fetchedFinalize = await finalize(
      purchaseRequestToken,
      data,
      correlationId
    );
    if (fetchedFinalize?.status !== 200) {
      setErrorOccurred(true);
      return null;
    }
    return fetchedFinalize?.response?.json();
  };

  const getTransactionInputs = async (data) => {
    const finalizePurchaseResponse =
      (await finalizePurchase(data)) ?? defaultError;

    if (finalizePurchaseResponse?.status === 'failed') {
      setErrorOccurred(true);
    }

    return finalizePurchaseResponse;
  };

  const getTransactionResult = async (data) => {
    setIsLoading(true);
    setIsProccesingPayment(true);
    setIs3dsView(false);
    setTransactionResult(await getTransactionInputs(data));
    setIsLoading(false);
    setIsProccesingPayment(false);
    setShowDropin(false);
  };

  const showFinalResult = async (response) => {
    setShowDropin(false);
    if (response?.errors?.length > 0) {
      setTransactionResult({
        status: response.status,
        reason: response.errors.map((item) => item)
      });
      return;
    }

    // Send null at non 3ds
    await getTransactionResult({
      details: {
        threeDSResult: null
      }
    });
  };

  async function initCheckout() {
    setIsLoading(true);
    const paymentMethods = await getPaymentMethods(
      purchaseRequestToken,
      correlationId
    );

    const adyenDropinConfig = {
      locale,
      environment: process.env.ADYEN_CLIENT_ENV,
      clientKey: process.env.ADYEN_CLIENT_KEY,
      paymentMethodsResponse: paymentMethods,
      onSubmit: async (state, dropin) => {
        dispatch(cardAuthorizeAndCapture());
        const response = await submitPayment(state.data);
        if (response?.action) {
          dropin.handleAction(response.action);
        } else {
          await showFinalResult(response);
        }
        setIsLoading(false);
      },
      onAdditionalDetails: (state) => {
        getTransactionResult(state.data);
      },
      onChange: (formState) => {
        setIsValid(formState?.isValid);
      },
      paymentMethodsConfiguration: {
        card: {
          showPayButton: false,
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: false,
          hideCVC: false,
          name: 'Credit or debit card'
        }
      },
      allowPaymentMethods: ['scheme']
    };
    const checkout = await AdyenCheckout(adyenDropinConfig);

    const { submit } = checkout.create('dropin').mount(dropinRef.current);

    submitRef.current = submit;
    setIsLoading(false);
  }

  useEffect(() => {
    initCheckout();
  }, []);

  return (
    <>
      {showDropin && (
        <>
          <AdyenDropInContainer>
            {!isLoading && !is3dsView && (
              <CardPaymentAmount>
                <DecimalFormatAmountWithCurrencyWithSpan />
              </CardPaymentAmount>
            )}
            <div ref={dropinRef} />
            {!isLoading && (
              <>
                {showPurchaseButton && (
                  <Button
                    type="button"
                    data-cy="cardCompletePaymentButton"
                    variant="expressive"
                    fullWidth="true"
                    disabled={!isValid}
                    onClick={() => {
                      dispatch(clickedCompleteCardPaymentButtonEvent());
                      submitRef.current();
                    }}
                  >
                    <span className="payment-button-text">
                      {t('card.complete_payment', {
                        defaultValue: 'Complete payment'
                      })}
                    </span>
                  </Button>
                )}
              </>
            )}
          </AdyenDropInContainer>
          {isLoading && !isProccesingPayment && (
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          )}
          {isLoading && isProccesingPayment && (
            <>
              <LoadingWrapperProcessing>
                <Loading />
              </LoadingWrapperProcessing>
              <ProcessingTitle>
                {t('card.is_processing_title', {
                  defaultValue: 'Processing payment'
                })}
              </ProcessingTitle>
              <ProcessingBody>
                {t('card.is_processing_body', {
                  defaultValue: "Hang on while we're processing your payment."
                })}
              </ProcessingBody>
            </>
          )}
        </>
      )}
      {errorOccurred && (
        <CardPaymentError
          transactionResult={
            transactionResult?.status !== 'succeeded' && transactionResult
          }
        />
      )}
    </>
  );
};

AdyenDropIn.propTypes = {
  history: propTypes.any.isRequired
};

const AdyenDropInContainer = styled.div.attrs({
  className: 'AdyenDropInContainer'
})`
  & .adyen-checkout__payment-method {
    background-color: ${colors.white};
    border: none;
  }

  & .adyen-checkout__label__text {
    font-size: ${(p) => p.theme.fontSize.base};
    font-weight: ${typography.weightMedium};
  }

  & .adyen-checkout__input {
    border-radius: 0;
  }

  & .adyen-checkout__payment-method__details,
  .ExpressiveButton {
    padding: 0 4px;
  }

  & .adyen-checkout__payment-method__header {
    display: none;
  }

  & .adyen-checkout__dropin--loading {
    display: none;
  }
}
`;

const CardPaymentAmount = styled.h1.attrs({ className: 'CardPaymentAmount' })`
  font-family: ${(p) => p.theme.font.primary};
  font-weight: bold;
  margin-top: ${spacing.spacing48};
  margin-bottom: ${spacing.spacing8};
  font-size: ${(p) => p.theme.fontSize.xLarge};
  text-align: center;
  width: 100%;
  span {
    font-family: ${(p) => p.theme.font.primary};
    font-weight: normal;
    font-size: ${(p) => p.theme.fontSize.base};
    margin-left: ${spacing.spacing4};
  }
`;

const ProcessingTitle = styled.div`
  margin-top: ${spacing.spacing32};
  font-size: ${(p) => p.theme.fontSize.base};
  line-height: 16px;
  font-weight: ${typography.weightBold};
`;

const ProcessingBody = styled.div`
  font-size: ${(p) => p.theme.fontSize.base};
  line-height: 24px;
  margin-bottom: ${spacing.spacing128};
`;

const LoadingWrapper = styled.div`
  width: 50px;
  min-height: 487px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapperProcessing = styled.div`
  width: 50px;
  min-height: 243px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export default AdyenDropIn;
