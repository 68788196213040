import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Button } from '@purpurds/purpur';

const ReturnToPaymentMethodsButton = ({ eventToEmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onRedirectButtonButtonClick = () => {
    dispatch(eventToEmit());
  };
  return (
    <Button
      type="button"
      variant="secondary"
      fullWidth="true"
      onClick={onRedirectButtonButtonClick}
      data-testid="limitToLowRedirectButton"
    >
      {t('credit_limit_information.declined_credit_redirect_button')}
    </Button>
  );
};

ReturnToPaymentMethodsButton.propTypes = {
  eventToEmit: PropTypes.func.isRequired
};

export default ReturnToPaymentMethodsButton;
