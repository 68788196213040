import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@purpurds/purpur';
import { cancelAuthentication } from '../actions/applicationActions';
import Loading from './Loading';
import {
  AuthAndSignWrapper,
  BoldText
} from '../styled-components/styledcomponents';
import BankIDView from './sbid/BankIDView';

const Authenticating = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isSwedishBankID } = useSelector((state) => state.app);

  const onCancelAuthentication = () => {
    dispatch(cancelAuthentication());
  };

  return (
    <>
      {isSwedishBankID && <BankIDView />}
      {!isSwedishBankID && (
        <AuthAndSignWrapper
          data-cy="Authenticating"
          wrapperClassName="AuthenticatingWrapper"
        >
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>

          <BoldText>{t('authenticating.waiting_message')}</BoldText>
          <Button
            type="button"
            variant="primary"
            fullWidth="true"
            onClick={onCancelAuthentication}
          >
            {t('authenticating.cancel')}
          </Button>
        </AuthAndSignWrapper>
      )}
    </>
  );
};
Authenticating.propTypes = {};
export default Authenticating;

const LoadingWrapper = styled.div`
  width: 50px;
  margin: 0 auto;
`;
