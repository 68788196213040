import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { spacing } from '@teliads/components/foundations';
import { Button } from '@purpurds/purpur';
import AuthenticatingQR from './AuthenticatingQR';
import AuthenticatingAutoStartButton, {
  AuthenticatingAutoStartType
} from './AuthenticatingAutoStartButton';
import { Column, TextButton } from '../../styled-components/styledcomponents';
import { cancelSbidAuthenticationOrSigning } from '../../actions/applicationActions';
import BankIDLogo from '../graphics/BankIDLogo';
import PoweredByTeliaFinance from '../PoweredByTeliaFinance';
import Loading from '../Loading';

// Not entirely reliably but good enough for this use case
const isMobile = () => /Android|iPhone|iPad/i.test(navigator.userAgent);

const BankIDView = () => {
  const [qrViewActive, setQrViewActive] = useState(!isMobile());
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { qr, isSignFlow, isAuthenticating } = useSelector(
    (state) => state.app
  );

  const onCancelAuthentication = () => {
    dispatch(cancelSbidAuthenticationOrSigning());
  };

  return (
    <Column>
      <Container>
        {!qr && (
          <LoadingContainer>
            <LoadingElement>
              <Loading />
            </LoadingElement>
            <p>
              {!isSignFlow &&
                isAuthenticating &&
                t('authenticating.identifying', {
                  defaultValue: 'Identifying...'
                })}
              {isSignFlow &&
                t('sign.signing', {
                  defaultValue: 'Signing...'
                })}
            </p>
          </LoadingContainer>
        )}
        {qrViewActive && qr && (
          <>
            <BankIDLogo />
            <p>
              {t('authenticating.description_app_at_other_device', {
                defaultValue:
                  'Start BankID-app on your device. Click QR-symbol in BankID-app. Point camera towards QR-code below'
              })}
            </p>
            <AuthenticatingQR />
            <ButtonSpacing>
              <AuthenticatingAutoStartButton
                type={AuthenticatingAutoStartType.textButton}
              />
            </ButtonSpacing>
            <Button
              type="button"
              variant="secondary"
              fullWidth="true"
              data-cy="CancelQR"
              onClick={onCancelAuthentication}
            >
              {t('authenticating.cancel')}
            </Button>
          </>
        )}
        {!qrViewActive && qr && (
          <>
            <BankIDLogo />
            <span>
              {!isSignFlow &&
                t('authenticating.description_app_at_same_device', {
                  defaultValue: 'Start BankID-app to identify at Telia Finance'
                })}
              {isSignFlow &&
                t('sign.description_app_at_same_device', {
                  defaultValue: 'Start BankID-app to sign at Telia Finance'
                })}
            </span>
            <ButtonSpacing>
              <AuthenticatingAutoStartButton
                type={AuthenticatingAutoStartType.button}
              />
            </ButtonSpacing>
            <ButtonSpacing>
              <Button
                type="button"
                variant="secondary"
                fullWidth="true"
                onClick={onCancelAuthentication}
              >
                {t('authenticating.cancel', {
                  defaultValue: 'Cancel'
                })}
              </Button>
            </ButtonSpacing>
            <TextButton onClick={() => setQrViewActive(true)}>
              {t('authenticating.use_app_at_other_device_text_button', {
                defaultValue: 'Use BankID on other device'
              })}
            </TextButton>
          </>
        )}
      </Container>
      <PoweredByContainer>
        <PoweredByTeliaFinance />
      </PoweredByContainer>
    </Column>
  );
};

const PoweredByContainer = styled.div`
  padding: ${spacing.spacing0} ${spacing.spacing0} ${spacing.spacing16}
    ${spacing.spacing0};
`;

const ButtonSpacing = styled.div`
  width: 100%;
  padding: ${spacing.spacing0} ${spacing.spacing0} ${spacing.spacing16}
    ${spacing.spacing0};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: ${spacing.spacing128} 0;
`;

const LoadingContainer = styled.div`
  width: 100%;
  min-height: 243px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const LoadingElement = styled.div`
  width: 50px;
`;

export default BankIDView;
