import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@purpurds/purpur';
import { startAuth } from '../actions/applicationActions';
import { clickedIdentifyButtonEvent } from '../actions/eventActions';
import AuthenticationErrorBox, {
  errorBoxTypes as errorBoxType
} from './AuthenticationErrorBox';

const Authentication = () => {
  const [identifyButtonEnabled, setIdentifyButtonEnabled] = useState(true);
  const {
    isAuthenticating,
    isAuthenticatingError,
    isAuthenticatingAlreadyInProgressError,
    country,
    redirectUrl,
    monthioCompletedError
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [windowRef, setWindowRef] = useState(null);

  const initWindowReference = () => {
    setWindowRef(window.open());
  };

  const onClickIdentify = () => {
    setIdentifyButtonEnabled(false);
    // We do not know if redirect url exists yet, so using country to determine
    // if new window shall be opened.
    if (country !== 'SE') {
      initWindowReference();
    }

    dispatch(clickedIdentifyButtonEvent());
    dispatch(startAuth());
  };

  useEffect(() => {
    // Now we have redirect url, set it in new window
    if (windowRef && redirectUrl) {
      windowRef.location = redirectUrl;
    }
  }, [redirectUrl, windowRef]);

  useEffect(() => {
    // Close window if authenticating flow ends in some way (success or fail)
    if (windowRef && !isAuthenticating) {
      setIdentifyButtonEnabled(true);
      windowRef.close();
    }
    if (country === 'SE' && !isAuthenticating) {
      setIdentifyButtonEnabled(true);
    }
  }, [isAuthenticating]);

  return (
    <AuthenticationWrapper data-cy="AuthenticationWrapper">
      <Button
        type="button"
        variant="expressive"
        fullWidth="true"
        data-cy="AuthenticationIdentifyButton"
        onClick={onClickIdentify}
        disabled={!identifyButtonEnabled}
      >
        {t('authentication.identify_button_label')}
      </Button>
      {isAuthenticatingAlreadyInProgressError && (
        <AuthenticationErrorBox
          errorText={t('authentication.error_bank_id_in_use')}
          type={errorBoxType.button}
        />
      )}
      {isAuthenticatingError ||
        (monthioCompletedError && (
          <AuthenticationErrorBox
            errorText={t('authentication.general_error')}
            type={errorBoxType.button}
          />
        ))}
    </AuthenticationWrapper>
  );
};

export default Authentication;

const AuthenticationWrapper = styled.div`
  width: 100%;
`;
