import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@purpurds/purpur';
import { cancelPayment } from '../actions/applicationActions';

const CancelButton = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onCancelButtonClick = () => {
    dispatch(cancelPayment());
  };

  return (
    <Button
      type="button"
      variant="secondary"
      fullWidth="true"
      onClick={onCancelButtonClick}
      data-cy="CancelPaymentButton"
    >
      {t('pay_in_parts.cancel_button_label')}
    </Button>
  );
};

export default CancelButton;
