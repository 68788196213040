import styled from 'styled-components';
import {
  borders,
  colors,
  spacing,
  typography
} from '@teliads/components/foundations';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@purpurds/purpur';
import Authentication from './Authentication';
import {
  CancelButtonContainer,
  FinePrint,
  Line,
  SecciAndTermsConditionLink,
  StrongBold
} from '../styled-components/styledcomponents';
import {
  clickedContinueButtonEvent,
  moreInformationEvent
} from '../actions/eventActions';
import { setShowMoreInformation } from '../actions/partPaymentActions';
import { selectCanChangePaymentMethod } from '../selectors/selectors';
import {
  questionsCheck,
  prepare
  // userCreditCheckQuestionsCheck
} from '../actions/applicationActions';
import Loading from './Loading';
import CancelButton from './CancelButton';
import FLOWS from '../constants/flows';
import useMerchantSpecificTranslationKey from '../hooks/useMerchantSpecificTranslationKey';
import AuthenticationErrorBox, {
  errorBoxTypes as errorBoxType
} from './AuthenticationErrorBox';

export default () => {
  const [continueButtonEnabled, setContinueButtonEnabled] = useState(true);

  const {
    flow,
    authentication,
    monthioCompletedError,
    kalpEnabled,
    kycEnabled,
    isLoading
  } = useSelector((state) => state.app);

  const { isPreparing, showMoreInformation } = useSelector(
    (state) => state.partPayment
  );

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const toggleShowMoreInformation = useCallback(() => {
    dispatch(setShowMoreInformation(!showMoreInformation));
    dispatch(moreInformationEvent(!showMoreInformation));
  }, [showMoreInformation]);
  const canChangePaymentMethod = useSelector(selectCanChangePaymentMethod);
  const clickedContinue = useCallback(() => {
    setContinueButtonEnabled(false);
    dispatch(clickedContinueButtonEvent());
    if (!kycEnabled && !kalpEnabled) {
      dispatch(prepare());
    } else if (kycEnabled || kalpEnabled) {
      dispatch(questionsCheck());
    }
    setContinueButtonEnabled(true);
  }, []);

  const { merchantTranslationKey, merchantTranslation } =
    useMerchantSpecificTranslationKey();
  return (
    <>
      <MoreInformationText>
        {flow === FLOWS.WEB
          ? merchantTranslation('pay_in_parts.more_information_details')
          : t(
              'pay_in_parts.more_information_details_in_store',
              'More information about'
            )}
        <MoreInformationTextButton
          onClick={toggleShowMoreInformation}
          canChangePaymentMethod={canChangePaymentMethod}
          data-cy="MoreInformationTextButton"
        >
          {flow === FLOWS.WEB
            ? t('pay_in_parts.more_information')
            : t('pay_in_parts.more_information_in_store', 'Part Payment')}
        </MoreInformationTextButton>
        .
      </MoreInformationText>

      <Line marginTop={spacing.spacing24} marginBottom={spacing.spacing24} />

      <FinePrint>
        <Trans
          i18nKey={merchantTranslationKey(
            'pay_in_parts.terms_and_conditions_credit_check_info'
          )}
        >
          <SecciAndTermsConditionLink
            href={t(
              'pay_in_parts.terms_and_conditions_credit_check_info_link_0'
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </SecciAndTermsConditionLink>
        </Trans>
      </FinePrint>
      {authentication.required && !authentication.done ? (
        <>
          <ButtonSpacing>
            <Authentication />
          </ButtonSpacing>
          {flow !== FLOWS.WEB && (
            <CancelButtonContainer>
              <CancelButton />
            </CancelButtonContainer>
          )}
        </>
      ) : (
        <>
          {isPreparing || isLoading ? (
            <LoadingContainer data-cy="PrepareNotCompleteViewLoading">
              <LoadingWrapper>
                <Loading />
              </LoadingWrapper>
            </LoadingContainer>
          ) : (
            <>
              <ButtonSpacing>
                <Button
                  type="button"
                  variant="expressive"
                  fullWidth="true"
                  disabled={!continueButtonEnabled}
                  onClick={clickedContinue}
                  data-testid="ContinueButton"
                >
                  {t('pay_in_parts.continue_button_label')}
                </Button>
              </ButtonSpacing>
              {monthioCompletedError && (
                <AuthenticationErrorBox
                  errorText={t('authentication.general_error')}
                  type={errorBoxType.button}
                />
              )}
              {flow !== FLOWS.WEB && (
                <CancelButtonContainer>
                  <CancelButton />
                </CancelButtonContainer>
              )}
            </>
          )}
        </>
      )}
      <EffectiveInterestRateTextBox>
        <EffectiveInterestRateTextPartOne>
          {merchantTranslation('pay_in_parts.interest_title')}
        </EffectiveInterestRateTextPartOne>
        <EffectiveInterestRateTextPartTwo>
          {merchantTranslation('pay_in_parts.interest_text_part_1')}
        </EffectiveInterestRateTextPartTwo>
        <EffectiveInterestRateTextPartTwo>
          <Trans
            i18nKey={merchantTranslationKey(
              'pay_in_parts.interest_text_part_2'
            )}
            components={{
              bold: <StrongBold />
            }}
          />
        </EffectiveInterestRateTextPartTwo>
      </EffectiveInterestRateTextBox>
    </>
  );
};

const MoreInformationText = styled.span.attrs({
  className: 'MoreInformationText'
})`
  font-size: ${(p) => p.theme.fontSize.xsmall};
  color: ${colors.gray600};
  margin: 0 ${spacing.spacing8};
`;

const MoreInformationTextButton = styled.button.attrs({
  className: 'MoreInformationTextButton'
})`
  padding: 0;
  margin-left: 3px;
  line-height: 1;
  font-size: ${(p) => p.theme.fontSize.xsmall};
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.color.primary};
  text-align: left;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.color.primaryHover};
  }
`;

const LoadingWrapper = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
`;

const LoadingContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
`;

const EffectiveInterestRateTextBox = styled.div`
  background-color: ${({ theme }) => theme.color.secondaryLayerBackground};
  color: ${(p) => p.theme.color.core.black};
  margin-top: ${spacing.spacing16};
  padding: ${spacing.spacing16};
  border-radius: ${borders.radiusSm};
  font-size: ${(p) => p.theme.fontSize.xsmall};
  line-height: ${spacing.spacing16};
  font-weight: ${typography.weightNormal};
`;

const EffectiveInterestRateTextPartOne = styled.div`
  font-weight: ${typography.weightBold};
`;

const EffectiveInterestRateTextPartTwo = styled.div`
  margin-top: ${spacing.spacing8};
`;

const ButtonSpacing = styled.div`
  width: 100%;
  padding: ${spacing.spacing24} ${spacing.spacing0} ${spacing.spacing0}
    ${spacing.spacing0};
`;
