import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { typography, spacing } from '@teliads/components/foundations';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@purpurds/purpur';
import PoweredByTeliaFinance from '../PoweredByTeliaFinance';
import FLOWS from '../../constants/flows';
import ReturnToPaymentMethodsButton from '../ReturnToPaymentMethodsButton';
import {
  userInfoCollectionCancelClicked,
  userInfoCollectionContinueClicked
} from '../../actions/applicationActions';
import PhoneField from './PhoneField';
import EmailField from './EmailField';
import useValidateEmail from './useValidateEmail';
import useValidatePhone from './useValidatePhone';

const UserInfoCollectionView = () => {
  const enablePhone = false;

  const { userCollectionApiError } = useSelector((state) => state.app);
  const { t } = useTranslation();

  const { flow } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [clickedContinue, setClickedContinue] = useState(false);
  const [apiError, setApiError] = useState({
    emailNotValid: undefined,
    phoneNotValid: undefined
  });
  const { emailValidation, validateEmail } = useValidateEmail();
  const { phoneValidation, validatePhone } = useValidatePhone();

  const continueButtonClick = useCallback(() => {
    if (!clickedContinue) {
      dispatch(userInfoCollectionContinueClicked());
      setClickedContinue(true);
    }
  }, []);

  useEffect(() => {
    setApiError((prevState) => ({
      ...prevState,
      emailNotValid: undefined,
      phoneNotValid: undefined
    }));

    setIsButtonEnabled(false);
    if (emailValidation.isEmailValid && emailValidation.hasEmailBeenValidated) {
      if (
        !enablePhone ||
        (phoneValidation.isPhoneValid && phoneValidation.hasPhoneBeenValidated)
      ) {
        setIsButtonEnabled(true);
      }
    }
  }, [phoneValidation, emailValidation]);

  useEffect(() => {
    setApiError((prevState) => ({
      ...prevState,
      emailNotValid: !!userCollectionApiError?.value?.errors?.find((item) => {
        return item.search('email') !== -1;
      }),
      phoneNotValid: !!userCollectionApiError?.value?.errors?.find((item) => {
        return item.search('phone') !== -1;
      })
    }));
    setIsButtonEnabled(apiError.phoneNotValid || apiError.emailNotValid);
  }, [userCollectionApiError]);

  return (
    <EmailPhoneCollectionContainer>
      <EmailPhoneCollectionHeader>
        {t('email_phone_collection.header', {
          defaultValue: 'Your contact information'
        })}
      </EmailPhoneCollectionHeader>
      <EmailPhoneCollectionBodyText>
        {t('email_phone_collection.body', {
          defaultValue:
            'Please check your contact information before proceeding. Text placeholder maybe info they are about to create a new credit account.'
        })}
      </EmailPhoneCollectionBodyText>
      <EmailField
        apiError={apiError}
        emailValidation={emailValidation}
        validateEmail={validateEmail}
      />
      {enablePhone && (
        <PhoneField
          apiError={apiError}
          phoneValidation={phoneValidation}
          validatePhone={validatePhone}
        />
      )}
      <Button
        type="button"
        variant="expressive"
        fullWidth="true"
        disabled={!isButtonEnabled}
        onClick={() => {
          if (isButtonEnabled) {
            continueButtonClick();
          }
        }}
        data-testid="EmailPhoneCollectionContinueButton"
      >
        {t('email_phone_collection.continue', {
          defaultValue: 'Continue'
        })}
      </Button>
      {flow !== FLOWS.WEB && (
        <ReturnToPaymentMethodsButtonContainer>
          <ReturnToPaymentMethodsButton
            eventToEmit={userInfoCollectionCancelClicked}
          />
        </ReturnToPaymentMethodsButtonContainer>
      )}
      <PoweredByTeliaFinance />
    </EmailPhoneCollectionContainer>
  );
};

const EmailPhoneCollectionContainer = styled.div.attrs({
  className: 'EmailPhoneCollectionContainer'
})`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: ${spacing.spacing0} ${spacing.spacing0} ${spacing.spacing16}
    ${spacing.spacing0};
`;

const EmailPhoneCollectionHeader = styled.h2.attrs({
  className: 'EmailPhoneCollectionHeader'
})`
  margin: ${spacing.spacing16} 0 ${spacing.spacing8} 0;
  font-size: ${(p) => p.theme.fontSize.base};
  line-height: 1.5;
  @media (min-width: ${(p) => p.theme.breakpoint.desktop}) {
    font-size: ${(p) => p.theme.fontSize.mediumSmall};
  }
`;

const EmailPhoneCollectionBodyText = styled.h2.attrs({
  className: 'EmailPhoneCollectionHeader'
})`
  margin: 0 0 ${spacing.spacing12} 0;
  font-size: ${(p) => p.theme.fontSize.small};
  line-height: 1.5;
  font-weight: ${typography.weightNormal};
  @media (min-width: ${(p) => p.theme.breakpoint.desktop}) {
    font-size: ${(p) => p.theme.fontSize.base};
  }
`;

const ReturnToPaymentMethodsButtonContainer = styled.div`
  margin: ${spacing.spacing24} ${spacing.spacing0} ${spacing.spacing0}
    ${spacing.spacing0};
`;

export default UserInfoCollectionView;
